import axios from 'axios';
const REACT_APP_LOGIN_API_URL = process.env.REACT_APP_LOGIN_API_URL

export const login = async (username, password) => {
  const url = `${REACT_APP_LOGIN_API_URL}/login`
  const headers = {
    'Content-Type': 'application/json'
  };
  const body = {
    username: username,
    password: password
  }
  try {
    const response = await axios.post(url, body, {headers: headers})
    return response
  } catch (error) {
    throw error
  }
}