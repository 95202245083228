import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "../Booking/booking.css";
import HeaderUserLogin from "../Shared/Header";
import FooterUserLogin from "../Shared/Footer";
import "react-datepicker/dist/react-datepicker.css";
import successImage from "../../img/ic_confirm.png";
import timeImage from "../../img/ic_time.png";

const RoomSaved = () => {
    const navigate = useNavigate();

    useEffect(() => {
      const redirectTimeout = setTimeout(() => {
        navigate("/rooms");
      }, 6000);
  
      return () => clearTimeout(redirectTimeout);
    }, []);

  return (
    <div className="login-container">
        <HeaderUserLogin />
        <img className="success-icon" src={successImage} alt="Success Icon" />
        <h1 className="title">
        <span style={{ color: "#00B7DD" }}>¡Guardado Exitoso!</span>
        </h1>
        <label className="label">
        Nombre de sala
        </label>
        <label className="label">
        Capacidad:
        </label>
        <label className="label">
        Estado: 
        </label>
        <div className="time-container">
        <img className="time-icon" src={timeImage} alt="Time Icon" />
        <label>
            Te redireccionaremos <br></br> automáticamente al inicio
        </label>
        </div>
        <FooterUserLogin />
    </div>
  )
}

export default RoomSaved
