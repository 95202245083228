import React from 'react'
import { Table } from 'reactstrap';

const AssistanceTable = ({booking}) => {
  if (!booking?.assistance) return <p>No hay asistentes registrados</p>
  return (
    <Table>
        <thead>
          <tr>
            <th>Nombre</th>
            <th>Apellido</th>
            <th>DNI</th>
            <th>Correo</th>
          </tr>
        </thead>
        <tbody>
          {booking.assistance.map((assistant, index) => (
            <tr>
              <td key={index}>{assistant.name}</td>
              <td key={index}>{assistant.lastname}</td>
              <td key={index}>{assistant.dni}</td>
              <td key={index}>{assistant.mail}</td>
            </tr>
          ))}
        </tbody>
      </Table>
  )
}

export default AssistanceTable