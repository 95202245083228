import React from 'react';
import Header from '../Shared/Header';
import Footer from '../Shared/Footer';
import Cancel from "../../img/fail.png";
import { useNavigate, useLocation } from 'react-router-dom';

const NotAvailable = () => {
  const location = useLocation();
  const booking = location.state.booking;

    const navigate = useNavigate();
    const handlerBackButtonClick = () => {
        navigate("/userPanel"); 
    };

    return (
        <>
            <Header/>
            <div className='center text-container'>
                <h2>Disponibilidad de Reservas</h2>
                <img src={Cancel} alt="Cancel Icon" />
                <h5 className='delete'>¡No Disponible!</h5>
                <h6>No tenemos disponibilidad en {booking.room.name} para el día {booking.date} de {booking.startHour}hs a {booking.endHour}hs. </h6>
                <h6>¡Te esperamos en otra ocasión!</h6>
                <br></br>
                <button className='btn-secondary' onClick={handlerBackButtonClick}>Volver atrás</button>
            </div>
            <Footer/>
        </>
    )
}

export default NotAvailable;
