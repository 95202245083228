import React from 'react'
import Header from '../Shared/Header';
import Footer from '../Shared/Footer';
import Cancel from "../../img/fail.png";
import { useLocation, useNavigate } from 'react-router-dom';
import { fixDateOffset } from '../../helpers/utils';

const CancelBooking = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const booking = location.state.booking;
  const handlerAcceptButtonClick = () => {
      navigate('/userPanel'); 
  };

  return (
    <>
        <Header/>
        <div className='center'>
            <h2>Cancelación de reservas</h2>
            <img src={Cancel}></img>
            <h5 className='delete'>¡Reserva cancelada!</h5>
            <h6>Cancelaste tu reserva de {booking.room.name} para el día {fixDateOffset(new Date(booking.date)).toLocaleDateString('es-ES', { year: "numeric", month: "long", day: "numeric" })} de {booking.startHour} a {booking.endHour}.</h6>
            <h6>¡Te esperamos en otra ocasión!</h6>
            <br></br>
            <button className='btn-secondary' onClick={handlerAcceptButtonClick}>Aceptar</button>
        </div>
        <Footer/>
    </>
  )
}

export default CancelBooking