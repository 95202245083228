import React, { useState } from "react";
import "./login.css";
import HeaderUserLogin from "../Shared/Header";
import FooterUserLogin from "../Shared/Footer";
import vectorImage from "../../img/addRegister.png";
import  { Navigate, useNavigate } from 'react-router-dom'
import { createUser } from "../../services/users/createUser";
import Swal from "sweetalert2";

const Register = () => {
    const navigate = useNavigate()
    const backHomeButton =()=>{
      navigate("/")
    }

    const [showMessage, setShowMessage] = useState(false)
    const [errMessage, setErrMessage] = useState("")

    const validateCharacter = (e) => {
        if(!/^([a-z0-9]+)$/i.test(e.key)){
            e.preventDefault()
        }
    }

    const validateNumber = (e) => {
        if(!/^([0-9]+)$/i.test(e.key)){
            e.preventDefault()
        }
    }

    const validateName = (e) => {
        if(!/^([a-z ]+)$/i.test(e.key)){
            e.preventDefault()
        }
    }

    const [newUser, setNewUser] = useState({name: "", lastname:"", dni: "", mail:"", password:"", repeatPass:""})

    const disabledButton =()=>{
        return !newUser.name || !newUser.lastname || !newUser.dni || !newUser.mail || !newUser.password || !newUser.repeatPass
    }

    const handleChange =(e)=>{
        const { target } = e;
        const { name, value } = target;

        const newValue = value.trim()

        const newValues = {
            ...newUser,
            [name]: newValue,
        };

        setNewUser(newValues);
    }

    const handleCreateUser = async () => {
        setErrMessage("")
        setShowMessage(false)
        if (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/i.test(newUser.mail))) {
            setErrMessage("El correo electrónico no es válido")
            setShowMessage(true)
            return
        }
        if (newUser.password.length < 8) {
            setErrMessage("La contraseña debe tener al menos 8 caracteres")
            setShowMessage(true)
            return
        }

        if (newUser.password === newUser.repeatPass) {
            try {   
                await createUser(newUser);
                navigate("/successRegister");
              } catch (err) {
                Swal.fire({title: "Error creando el usuario", icon:"error"});
              }
        } else {
            setErrMessage("Las contraseñas no coinciden")
            setShowMessage(true)
        }
    }

  return (
    <>
        <HeaderUserLogin/>
        <main className='main-gradient'>
            <img  src={vectorImage} alt="User" />
            <h2 className="title">
                Completa el formulario para registrarte <br/>
                y disfrutar de los beneficios de <span>Eureka</span> 
            </h2>
            <button onClick={backHomeButton} className="btn-secondary">Volver a home</button>
        </main>
        <div className='center'>
            <div className="input-container">
                <p className="subtitle">Nombre</p>
                <input
                className="rounded-select"
                type="text"
                name="name"
                value={newUser.name}
                onChange={handleChange}
                onKeyPress={(e) => validateName(e)}
                />
            </div>
            <div className="input-container">
                <p className="subtitle">Apellido</p>
                <input
                className="rounded-select"
                type="text"
                name="lastname"
                value={newUser.lastname}
                onChange={handleChange}
                onKeyPress={(e) => validateName(e)}
                />
            </div>
            <div className="input-container">
                <p className="subtitle">DNI</p>
                <input
                className="rounded-select"
                type="text"
                maxlength="8"
                name="dni"
                value={newUser.dni}
                onChange={handleChange}
                onKeyPress={(e) => validateNumber(e)}
                />
            </div>
            <div className="input-container">
                <p className="subtitle">Correo Electrónico</p>
                <input
                className="rounded-select"
                type="text"
                name="mail"
                value={newUser.mail}
                onChange={handleChange}
                />
            </div>
            <div className="input-container">
                <p className="subtitle">Contraseña</p>
                <input
                className="rounded-select"
                type="password"
                name="password"
                value={newUser.password}
                onChange={handleChange}
                onKeyPress={(e) => validateCharacter(e)}
                />
            </div>
            <div className="input-container">
                <p className="subtitle">Repetir Contraseña</p>
                <input
                className="rounded-select"
                type="password"
                name="repeatPass"
                value={newUser.repeatPass}
                onChange={handleChange}
                onKeyPress={(e) => validateCharacter(e)}
                />
            </div>
            {showMessage && <h6 className={`pass-error-message`}>{errMessage}</h6>}
            <div className='btn-box'>
                <button disabled={disabledButton()} onClick={handleCreateUser} className="btn-primary">Registrarse</button>
            </div>
        </div>
        <FooterUserLogin/>
    </>
  )
}

export default Register