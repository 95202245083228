import React, { useState } from 'react';
import { faAngleUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./ContactButtons.css";
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';

export const ContactButtons = () => {

  let scrollUp =()=>{
    const currentScroll = document.documentElement.scrollTop;
    if(currentScroll > 0){
        window.scrollTo({top: 0, behavior: 'smooth' })
    }
  }

  const [style, setStyle] = useState("");

  window.onscroll = function(){

    let scroll = document.documentElement.scrollTop;
    
    if(scroll > 400){
      setStyle("show")
    } else {
      setStyle("")
    }

  }

  return (
    <>
    <a
      href="https://api.whatsapp.com/message/UTN2355RW22MP1"
      target="_blank"
      rel="noopener noreferrer"
      className={`buttonContact show`}
      
    >
      <FontAwesomeIcon icon={faWhatsapp} style={{color: "white"}} />
    </a>

    <button className={`buttonUp ${style}`} onClick={scrollUp}>
      <FontAwesomeIcon icon={faAngleUp}/>
    </button>
    </>
  );
}