import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./AdminUsers.css";
import HeaderUserLogin from "../Shared/Header";
import FooterUserLogin from "../Shared/Footer";
import imageLine from "../../img/big-line.png";
import ellipse from "./../../img/ellipse1.png";
import edit from "./../../img/edit-ic.png";
import editUser from "./../../img/edit-user.png"
import disabled from "./../../img/disabled.png";
import usersimg from "./../../img/users.png";
import { getUsers } from '../../services/users/getUsers';
import { updateUsersById } from "../../services/users/updateUsersById";
import {resetPassword} from "../../services/users/resetPassword"
import { deactivateUser } from "../../services/users/deactivateUser";
import Swal from "sweetalert2";
import { bfsaUserTypes, eurekaUserTypes } from "../../dictionaries/users";

const AdminUsers = () => {
    const navigate = useNavigate();
    const [users, setUsers] = useState([]);
    const [userSelected, setUserSelected] = useState({})
    const [disable, setDisable] = useState("");
    const [show, setShow] = useState("");
    const [hide, setHide] = useState("");
    const [creditsToAdd, setCreditsToAdd] = useState(0);
    const [newMembership, setNewMembership] = useState("");
    const [newOrigin, setNewOrigin] = useState("");
    const [filter, setFilter] = useState("")
    const [editCredits , setEditCredits] = useState(false)


    const handleImg = (id) => {
        try {
            return require(`./../../img/fotos/${id}.jpg`);
          } catch (error) {
            return require("./../../img/people-default1.png")
          }
    }

    const handleOrigin = (value) => {
      debugger
      setNewOrigin(value)
      setNewMembership('')
    }
    
    const handleDeactivateUser = async ()=>{
        if (!window.confirm("¿Está seguro que desea desactivar este usuario?")) return
        try{
            await deactivateUser(userSelected.id)
            Swal.fire({title:"Usuario desactivado", icon: "success"})
            window.location.reload()
        } catch(err){
            Swal.fire({title: "Error al desactivar usuario", icon: "error"})
        }
    }

    const handleResetPassword = async ()=>{
        if (!window.confirm("¿Está seguro que desea blanquear la contraseña?")) return
        try{
            await resetPassword(userSelected.id)
            Swal.fire({title: "Contraseña blanqueada correctamente. \nLa nueva contraseña es el DNI del usuario", icon:"success"})
            setUserSelected({})
        } catch(err){
            Swal.fire({title:"Error al blanquear la contraseña", icon:"error"})
        }
    }

    const handleUpdate = async () => {
        if (!newOrigin || !newMembership) return Swal.fire({title: "Debe seleccionar un origen y un tipo de usuario", icon: "warning"})
        if (!window.confirm("¿Está seguro que desea actualizar los datos del usuario?")) return
        const newCredits = userSelected.membership.credits + creditsToAdd || 0
        const userToUpdate = {...userSelected, origin: newOrigin, membership: {...userSelected.membership, credits: newCredits, type: newMembership}}
        try{
            await updateUsersById(userToUpdate);
            Swal.fire({title: "Usuario actualizado", icon: "success"})
            setUserSelected({})
            window.location.reload() 
        } catch(err){
            Swal.fire({title: "Error al actualizar", icon: "error"})
        }
    }

     useEffect(() => {
        const fetchUsers = async () => {
            try {
                const data = await getUsers();
                setUsers(data);
            } catch (error) {
                Swal.fire({title: 'Error al traer los usuarios', icon:"error"});
            }
        };
        fetchUsers();
    }, []);

    const handleDisableBtn=(user)=>{
        setUserSelected(user)
        setNewMembership(user.membership.type)
        setNewOrigin(user.origin)
        setCreditsToAdd(0)
        setDisable("disable")
        setShow("show")
        setHide("hide")
        setUserSelected(user)
    }

    const handlerEditUser=(user)=>{
        setUserSelected(user)
        setNewMembership(user.membership.type)
        setNewOrigin(user.origin)
        setCreditsToAdd(0)
        setDisable("")
        setShow("")
        setHide("")
    }

    const handleCancelBtn=()=>{
        setDisable("")
        setShow("")
        setHide("")
    }

    const backPanel =()=>{
        navigate("/userPanel")
    }
    
  return (
    <>
        <HeaderUserLogin />
        <main className="main-admin center">
            <img src={usersimg}></img>
            <h2 className="main-title">Administración de socios Eureka</h2>
            <button onClick={backPanel} className="btn-secondary">Regresar al panel</button>
        </main>
        <section className="cards-container">
            <aside className="card card--aside">
                    <label htmlFor="Filtro">Buscar por Nombre</label>
                    <div className="filter-width">
                        <input
                          type="text"
                          id="filtro"
                          className="filter"
                          value={filter}
                          onChange={(e)=>setFilter(e.target.value)}
                        />
                    </div>
                    <div className="cards-box">
                        {
                        users.filter(user => user.name.toLowerCase().includes(filter.toLowerCase()) || user.lastname.toLowerCase().includes(filter.toLowerCase())).map((user, index) =>
                        <card key={index} className="card card--users row">
                            <div className="profile-pic center col-md-2">
                                <img className="aside-image" src={ellipse} alt="Ellipse" />
                                    <img
                                    className="user-img"
                                    src={handleImg(user.membership?.id)}
                                    alt="User Default"
                                    />
                            </div>
                            <div className="col-md-5 users-info">
                                <h6 style={{marginBottom: 0}}>{user.name + ' ' + user.lastname}</h6>
                                {user.membership?.type === "mentor" ? <p>Mentor</p> : user.membership?.id ? <p>Socio: {user.membership.id} </p> : <p> - </p>}
                                
                            </div>
                            <div className="col-md-5 edit-users">
                                <div onClick={()=>handlerEditUser(user)}>
                                    <span className="edit" >Editar </span>
                                    <img src={edit} alt="Editar Usuario"></img>
                                </div>
                                <div onClick={()=>handleDisableBtn(user)}>
                                    <span className="standby">Desactivar</span>
                                    <img src={disabled} alt="Desactivar Usuario" />
                                </div>
                            </div>
                        </card>
                        )}
                    </div>
            </aside>
            <div>
                <div className="login-container">
                    <div className={`card card--adminUser ${disable}`}>
                        {userSelected?.membership ? 
                            <div className='center'>
                                <div className="center col-md-2 profilePhotoContainer">
                                    <img className="aside-image-selected" src={ellipse} alt="Ellipse" />
                                    <img className="user-img-selected" src={handleImg(userSelected.membership?.id)} alt="User Default" />
                                </div>
                                <div className="userData center">
                                    <h2 className="adminUser-title">{`${userSelected.name} ${userSelected.lastname}`}</h2>
                                    <div><label>Verificación de identidad:</label>
                                        <input
                                            disabled={userSelected.validated}
                                            type="checkbox"
                                            name="validated"
                                            value={userSelected.validated ? userSelected.validated : false}
                                            checked={userSelected.validated ? userSelected.validated : false}
                                            onChange={(e)=>setUserSelected({...userSelected, validated: e.target.checked})}
                                        />
                                    </div>
                                    <div><label>Usuario:</label> {userSelected.username}</div>
                                    <div><label>DNI:</label> {userSelected.dni}</div>
                                    <div><label>Proyecto:</label> {userSelected.project}</div>
                                    <div><label>Promoción:</label> {userSelected.membership?.year}</div>
                                    <div><label>Número de socio:</label> {userSelected.membership?.id}</div>
                                    <div><label>Creditos:</label> {userSelected.membership?.credits}</div>
                                </div>
                                <label htmlFor="origin">Origen del Usuario</label>
                                <div className="input-group">
                                    <select
                                        className="select" 
                                        value={newOrigin}
                                        onChange={(e)=>handleOrigin(e.target.value)}
                                    >
                                        <option value="">Seleccionar...</option>
                                        <option value="eureka">Fundación Eureka</option>
                                        <option value="bfsa">Banco de Formosa</option>
                                    </select>
                                </div>
                                <label htmlFor="membresia">Tipo de Usuario</label>
                                <div className="input-group">
                                    <select
                                        className="select" 
                                        value={newMembership}
                                        onChange={(e)=>setNewMembership(e.target.value)}
                                        disabled={!newOrigin}
                                    >
                                        <option value="">Seleccionar...</option>
                                        <option value="basic">Basica</option>
                                        {
                                          newOrigin === "eureka" && eurekaUserTypes.map((type, index) => <option key={index} value={type.value}>{type.label}</option>)
                                        }
                                        {
                                          newOrigin === "bfsa" && bfsaUserTypes.map((type, index) => <option key={index} value={type.value}>{type.label}</option>)
                                        }
                                    </select>
                                </div>
                                <label htmlFor="creditos">Agregar créditos:</label>
                                <div className="input-group m0 input-group-credits">
                                    <input
                                        type="number"
                                        pattern="[0-9]"
                                        min={0}
                                        id="creditos"
                                        className="input-field"
                                        name="credits"
                                        onChange={(e)=>setCreditsToAdd(parseInt(e.target.value))}
                                        value={creditsToAdd}
                                        disabled={!editCredits}
                                        style={{borderRadius: '18px', width: '100%'}}
                                    />
                                    <img src={edit} onClick={() => setEditCredits(!editCredits)} alt="Editar Usuario" className="edit-credits"/>
                                </div>
                                <h5 className={`disable-message ${show}`}>¿Está seguro que desea desactivar este usuario?</h5>
                                <div className={`center ${hide}`}>
                                    <button className="btn-secondary" onClick={handleResetPassword}>Blanqueo de clave</button>
                                    <button className="btn-primary btn-primary--save"  onClick={()=>handleUpdate()}>Guardar cambios</button>
                                </div>
                                <div className={`disable-buttons center ${show}`}>
                                    <button className="btn-delete btn-delete--disable" onClick={handleDeactivateUser}>Desactivar</button>
                                    <button onClick={()=>handleCancelBtn()} className="btn-secondary">Cancelar</button>
                                </div>
                                <div className="bottom-image">
                                    <img src={imageLine} alt="line" className="bottom-image" />
                                </div>
                            </div>

                            :
                            <div className="center">
                                <img width={100} src={editUser}></img>
                                <h3 className="default-message">Selecioná el usuario que desees editar o desactivar</h3>
                                <div className="bottom-image">
                                    <img src={imageLine} alt="line" className="bottom-image" />
                                </div>
                            </div>
                            
                        }
                    </div>
                </div>
            </div>
        </section>
        <FooterUserLogin />
    </>
  )
}

export default AdminUsers