import React, { useEffect } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import "../Booking/booking.css";
import HeaderUserLogin from "../Shared/Header";
import FooterUserLogin from "../Shared/Footer";
import "react-datepicker/dist/react-datepicker.css";
import successImage from "../../img/ic_confirm.png";
import timeImage from "../../img/ic_time.png";
import { fixDateOffset } from "../../helpers/utils";


const SuccessBooking = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const booking = location.state.booking;

  useEffect(() => {
    const redirectTimeout = setTimeout(() => {
      navigate("/userPanel");
    }, 6000);

    return () => clearTimeout(redirectTimeout);
  }, []);

  return (
    <div className="login-container">
      <HeaderUserLogin />
      <h1 className="title">¡Reservas para: “{booking.room.name}”</h1>
      <img className="success-icon" src={successImage} alt="Success Icon" />
      <h1 className="title">
        <span style={{ color: "#00B7DD" }}>¡Reserva confirmada!</span>{" "}
      </h1>
      <label className="label">
        Te esperamos el día {fixDateOffset(new Date(booking.date)).toLocaleDateString('es-ES', { year: "numeric", month: "long", day: "numeric" })} de {booking.startHour}hs a {booking.endHour}hs.
      </label>
      <label className="label">¡Que lo disfrutes!</label>
      <div className="time-container">
        <img className="time-icon" src={timeImage} alt="Time Icon" />
        <label>
          Te redireccionaremos <br></br> automáticamente al inicio
        </label>
      </div>
      <FooterUserLogin />
    </div>
  );
};

export default SuccessBooking;
