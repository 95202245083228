
import React from "react";
import HeaderUserLogin from "../Shared/Header";
import FooterUserLogin from "../Shared/Footer";
import "./scanner.css"
import imageLine from "../../img/image-bg-1.png";
import  { useNavigate } from 'react-router-dom'
import { getBookingsById } from '../../services/bookings/getBookingsById';
import Html5QrcodePlugin from "./Html5QrcodePlugin";

const Scanner = () => {
  function onScanSuccess(decodedText, decodedResult) {
    const result = JSON.parse(decodedText)
    getBookingsById(result.id).then(bookingData => {
      navigate("/verifyDNI", {state: { booking: bookingData } })
    }).catch(error => {
      console.log(error)
    })
}
  const navigate = useNavigate()
  const handlerBackButton =()=>{
    navigate("/userPanel")
  }

  return(
    <div className='scanner-container'>
        <HeaderUserLogin/>
          <div className='scanner-box'>
            <h2 className='title'>Escaner QR</h2>
            <Html5QrcodePlugin
              fps={5}
              qrbox={{
                width: 250,
                height: 250,
              }}
              disableFlip={false}
              qrCodeSuccessCallback={onScanSuccess}
              />
            <button className="btn-secondary" onClick={handlerBackButton}>Volver al panel</button>
            <div className="bottom-image">
              <img src={imageLine} alt="line" className="bottom-image" />
            </div>
          </div>
        <FooterUserLogin/>
    </div>
  )
}

export default Scanner