import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { getRooms } from '../../services/rooms/getRooms';
import { UserContext } from "../../context/UserContext";
import addImage from "../../img/icon_add.png";
import Header from '../Shared/Header';
import Footer from '../Shared/Footer';
import hall from "../../img/buildings.png";
import './rooms.css';
import Swal from 'sweetalert2';

const Rooms = () => {
  const [rooms, setRooms] = useState([]);
  const navigate = useNavigate();
  const { user } = useContext(UserContext);

  useEffect(() => {
    const fetchRooms = async () => {
      try {
        const data = await getRooms();
        setRooms(data);
      } catch (error) {
        Swal.fire({title:'Error al traer las reservas', icon:"error"});
      }
    };
    fetchRooms();
  }, []);

  const handleBackButtonClick = () => {
    navigate('/userPanel');
  };

  const handlerNewButton = () => {
    navigate('/newRoom');
  };

  const handlerEditButton = (room) => {
    navigate(`/editRoom/${room.id}`, { state: { roomData: room } });
  };

  return (
    <>
      <Header />
      <div className='center'>
        <h2>Gestión de disponibilidad de salas y puestos de trabajo</h2>
        {rooms.map((room) => (
          <div key={room.id} className='card card--rooms center'>
            <div className='card--rooms__title'>
              <img width={60} height={60} src={hall} alt="Sala" />
              <h5>{room.name}</h5>
            </div>
            <hr></hr>
            <div className='card--rooms__items'>
              <h6>Tipo de sala: {room.type === 'room' ? 'Sala' : 'Puesto de trabajo'}.</h6>
              <h6>Activa: {room.active ? 'Sí' : 'No'}</h6>
              <h6>Capacidad: {room.capacity} personas.</h6>
            </div>
            <div className='room-buttons'>
              <button className='btn-primary' onClick={() => handlerEditButton(room)}>Editar</button>
              
            </div>
          </div>
        ))}
        <button className='btn-primary add-room' onClick={handlerNewButton}>
          <img className="search-icon" src={addImage} alt="Add Icon" />
          Crear Nueva Sala
        </button>
        <button className='btn-secondary' onClick={handleBackButtonClick}>Volver atrás</button>
      </div>
      <Footer />
    </>
  );
}

export default Rooms;
