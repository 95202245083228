import React, { useEffect, useState } from "react";
import  { useLocation, useNavigate } from 'react-router-dom';
import HeaderUserLogin from "../Shared/Header";
import FooterUserLogin from "../Shared/Footer";
import imageLine from "../../img/image-bg-1.png";
import "./scanner.css"
import { getUserByDni } from '../../services/users/getUserByDni';

import { fixDateOffset } from "../../helpers/utils";

const VerifyDNI = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const booking = location.state?.booking;
  const [inputDni, setInputDni] = useState("");

  const validateNumber = (e) => {
      if(!/^([0-9]+)$/i.test(e.key)){
          e.preventDefault()
      }
  }

  const handlerBackButton =()=>{
    navigate("/userPanel")
  }

  const userVerify = async() => { 
    try {
      const data = await getUserByDni(inputDni)
      navigate("/registerEntry", {state: {assistanceUser: data, bookingId: booking.id, dni: inputDni} });
    } catch (error) {
      navigate("/registerEntry", {state: {dni: inputDni, bookingId: booking.id} })
    }
  }

  const handleInputChange = (event) => {
    setInputDni(event.target.value);
  };

  const bookingDay = JSON.stringify(booking.date)

  return(
    <div className='scanner-container'>
        <HeaderUserLogin/>
          <div className='scanner-box'>
            <div className='center'>
              <h2 className='title'>
                {JSON.stringify(booking.room.name)}
              </h2>
              <h5>
                Reservada para el día {fixDateOffset(new Date(bookingDay)).toLocaleDateString('es-ES', { year: "numeric", month: "long", day: "numeric" })} 
              </h5>
              <h5>de {JSON.stringify(booking.startHour). replace(/[ '"]+/g, ' ')} a {JSON.stringify(booking.endHour). replace(/[ '"]+/g, ' ')} horas.</h5>
            </div>
          <div className="input-container margin-20">
            <p className="subtitle">DNI</p>
            <input
              className="rounded-select"
              type="text"
              maxLength="8"
              name="dni"
              value={inputDni}
              onChange={handleInputChange}
              onKeyPress={(e) => validateNumber(e)}
              />
            </div>
              <button className="btn-primary" disabled={inputDni.length < 7} onClick={async () => await userVerify()}>Verificar</button>
              <button className="btn-secondary" onClick={handlerBackButton}>Volver al panel</button>
            <div className="bottom-image">
              <img src={imageLine} alt="line" className="bottom-image" />
            </div>
          </div>
        <FooterUserLogin/>
    </div>
  )
}

export default VerifyDNI