import React, { useState, useEffect, useContext } from "react";
import "../Booking/booking.css";
import HeaderUserLogin from "../Shared/Header";
import FooterUserLogin from "../Shared/Footer";
import searchImage from "../../img/ic_search.png";
import vectorImage from "../../img/ic_vector.png";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import es from "date-fns/locale/es";
import { useLocation, useNavigate } from 'react-router-dom';
import { getRooms } from "../../services/rooms/getRooms";
import { checkBookingAvailability } from "../../services/bookings/checkBookingAvailability";
import { UserContext } from "../../context/UserContext";
import { fixDateOffset } from "../../helpers/utils";
import { addBusinessDays, addDays } from "date-fns";
import Swal from "sweetalert2";
import { freeBookingUserTypes } from "../../dictionaries/users";
import { holidays } from "../../helpers/holidays";

const today = new Date()
const initialBookingState = {
  "endHour": "",
  "date": addBusinessDays(today, 2),
  "active": true,
  "capacity": null,
  "userDni": "",
  "project": "",
  "user": {},
  "startHour": "",
  "id": "",
  "bookingPrice": "",
  "room": {}
}


const Booking = () => {
  const location = useLocation();
  const bookingToEdit = location.state?.booking;
  const [booking, setBooking] = useState(bookingToEdit ? bookingToEdit : initialBookingState);
  const [rooms, setRooms] = useState([]);
  const { user } = useContext(UserContext)

  const handleDisabledButton = () => {
    return !booking.room?.id || !booking.capacity || (booking.startHour >= booking.endHour)
  };

  const handleSubmit = async () => {
    const price = freeBookingUserTypes.indexOf(user.membership?.type) > -1 ? 0 : parseInt(booking.endHour.split(':')[0]) - parseInt(booking.startHour.split(':')[0])
    const previousPrice = bookingToEdit ? bookingToEdit.bookingPrice : 0
    const bookingToReserve = {...booking, date: new Date(booking.date).toISOString().split('T')[0], project: user.project, userDni: user.dni, user: {...user}, bookingPrice: price}
    debugger
    try {
      await checkBookingAvailability(bookingToReserve);
      navigate("/confirmBooking", {state: {booking: bookingToReserve, previousPrice} });
    } catch (e) {
      if(e.response.status === 404){
        navigate("/notAvailable", {state: {booking: bookingToReserve} });
      } else {
        navigate("/bookingError", {state: {booking: bookingToReserve, message:e.response.data.message}})
      }
    }
  }
  
  const handleChange = ({name, value}) => {
    debugger
    switch (name) {
      case 'room':
        setBooking(prevState => ({...prevState, [name]: value, capacity: null}))
        break;
      default:
        setBooking(prevState => ({...prevState, [name]: value}))
        break;
    }
  }

  const fetchAllRooms = async () => {
    try {
      const data = await getRooms();
      const filterRooms = data.filter(room => room.active && (!room.origin || user.origin === room.origin))
      setRooms(filterRooms)
    } catch (error) {
      Swal.fire( 
      {
        title: 'Error al traer las salas', 
        icon: 'warning'
      });
    }
  };

  useEffect(() => {
    fetchAllRooms()
  }, []);

  const generateTimeOptions = () => {
    if (!user.origin) {
      const options = [];
      for (let hour = 7; hour < 19; hour++) {
        const formattedHour = hour.toString().padStart(2, "0");  
        options.push(`${formattedHour}:00 hs`);
      }
      return options;
    }
    if (user.origin === 'bfsa') {
      const options = [];
      for (let hour = 7; hour < 13; hour++) {
        const formattedHour = hour.toString().padStart(2, "0");  
        options.push(`${formattedHour}:00 hs`);
      }
      return options;
    } else {
      const options = [];
      for (let hour = 12; hour < 19; hour++) {
        const formattedHour = hour.toString().padStart(2, "0");  
        options.push(`${formattedHour}:00 hs`);
      }
      return options;
    }
  };

  const customDateFormat = "EEEE, dd/MM/yyyy";

  const navigate = useNavigate();
  const handlerBackButton = () => {
    navigate(-1); 
  };

  return (
    <div className="login-container">
      <HeaderUserLogin />
      <main className="main-gradient">
        <img className="vector-icon" src={vectorImage} alt="Search Icon" />
        <h1 className="title">
          ¡Asegura <span style={{ color: "#00B7DD" }}>tu lugar</span> en nuestras
          salas y <br></br> puestos de trabajo!{" "}
        </h1>
      </main>
      <div className="select-container">
        <p className="subtitle">Tipo de reserva</p>
        <select disabled={booking.id} value={booking.room?.id} className="rounded-select" onChange={(e) => handleChange({name: 'room', value: rooms.find(room => room.id === e.target.value)})} >
          <option value="">Seleccionar una sala...</option>
          {
            rooms.map((room, index) => (
              <option key={index} value={room.id}>{room.name}</option>
            ))
          }
        </select>
      </div>

      <div className="select-container">
        <p className="subtitle">¿Para cuándo deseas reservar?</p>
        <DatePicker
          excludeDates={holidays.map(holiday => addDays(new Date(holiday.date), 1))}
          selected={fixDateOffset(new Date(booking.date))}
          onChange={(date) => handleChange({name: 'date', value: new Date(date).toISOString().split('T')[0] })}
          dateFormat={customDateFormat}
          filterDate={(date) => date.getDay() !== 0 && date.getDay() !== 6}
          locale={es}
          minDate={addBusinessDays(new Date(), 2)}
          className="rounded-select"
        />
      </div>

      <div className="select-hour-container">
        <div className="time-select-wrapper">
          <p className="subtitle">Hora de inicio</p>
          <select
            className="rounded-select-hour"
            onChange={(e) => handleChange({name: 'startHour', value: e.target.value.replace(' hs', '')})}
            value={`${booking.startHour} hs`}
          >
            <option key={""} value={""}>Seleccionar...</option>
            {generateTimeOptions().slice(0, -1).map((option, index) => (
              <option key={index} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>
        <div className="time-select-wrapper">
          <p className="subtitle">Hora de fin</p>
          <select
            className="rounded-select-hour"
            onChange={(e) => handleChange({name: 'endHour', value: e.target.value.replace(' hs', '')})}
            value={`${booking.endHour} hs`}
          >
            <option key={""} value={""}>Seleccionar...</option>
            {generateTimeOptions().slice(1).map((option, index) => (
              <option key={index} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="select-container mb-20">
        <p className="subtitle">Número de personas</p>
        <select className="rounded-select" value={booking.capacity} onChange={(e) => handleChange({name: 'capacity', value: e.target.value}) }>
          <option value="">Seleccionar una opción...</option>
          {
            booking.room?.type === 'workstation' && <option value="1">1</option>
          }
          {
            booking.room?.type === 'room' && Array(booking.room?.capacity).fill().map((x,i)=> (<option key={i+1} value={i+1}>{i+1}</option>) )
          }
        </select>
      </div>
      <button className="btn-primary" onClick={handleSubmit} disabled={handleDisabledButton()}>
        <img className="search-icon" src={searchImage} alt="Search Icon" />
        Buscar disponibilidad
      </button>
      <button className="btn-secondary" onClick={handlerBackButton}>Volver atrás</button>

      <FooterUserLogin />
    </div>
  );
};

export default Booking;
