import React from 'react';
import ReactPlayer from "react-player";
import "./clips.css";
import { messages } from "./messages-clips";

const Clips = () => {
  return (
    <>
        <div>
            <b className="bullet">{messages.main.bullet}</b> <b>{messages.main.pretitle}</b>
            <h2>
                {messages.main.title}
            </h2>
            <h6>
                {messages.main.subtitle}
            </h6>
        </div>
        <div className='clips-container row'>
            <div className='clip1 col-md-6 col-sm-12'>
                <ReactPlayer
                    url="https://www.youtube.com/watch?v=wzioU_Us1Iw&ab_channel=eurekaideasenmarcha"
                    width="100%"
                    height="100%"
                    controls
                />
                <span>
                    {messages.main.clip1}
                </span>
            </div>
            <div className='clip2 col-md-6 col-sm-12'>
                <ReactPlayer
                    url="https://www.youtube.com/watch?v=Dx19qvBKw-Q&ab_channel=eurekaideasenmarcha"
                    width="100%"
                    height="100%"
                    controls
                />
                <span>
                    {messages.main.clip2}
                </span>
            </div>
        </div>
    </>
  )
}

export default Clips