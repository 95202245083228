import { jwtDecode } from "jwt-decode"

const TOKEN_EXPIRES = localStorage.getItem('token_expires')
const ACCESS_TOKEN = localStorage.getItem('access_token')
const USER = JSON.parse(localStorage.getItem("user"))

export const validateSession = () => {
  if (!TOKEN_EXPIRES || TOKEN_EXPIRES < Date.now() || !ACCESS_TOKEN){
    return false
  } 
  return true
}

export const validateUserIdentity = () => {
  const decodedUser = jwtDecode(ACCESS_TOKEN)
  const validated = decodedUser.validated
  if (validated){
    return true
  } 
  return false
}

export const fixDateOffset = (date) => {
  const aux = new Date(date);
  aux.setMinutes(date.getMinutes() + date.getTimezoneOffset());
  return aux;
}

export const handleLocalStorageCredits = (newCredits) => {
  localStorage.setItem("user", JSON.stringify({...USER, membership: {...USER.membership, credits: newCredits}}));
}