import React, { useState, useContext } from "react";
import imageLine from "../../img/image-bg-1.png";
import "./login.css";
import HeaderUserLogin from "../Shared/Header";
import FooterUserLogin from "../Shared/Footer";
import { useNavigate } from 'react-router-dom';
import { changeUserPassword } from "../../services/users/changeUserPassword";
import { UserContext } from "../../context/UserContext";
import Swal from "sweetalert2";

const validateCharacter = (e) => {
    if(!/^([a-z0-9]+)$/i.test(e.key)){
        e.preventDefault()
    }
}


const ChangePass = () => {
    
    const { user } = useContext(UserContext)
    const navigate = useNavigate();
    const handlerBackButton = () => {
        navigate(-1); 
    };

    const handlerChangePasswordButton = async () => {
        if(newPassword.length > 7 && confirmPassword.length > 7){
            if(newPassword === confirmPassword){
                try {
                    await changeUserPassword(user.id, password, newPassword, confirmPassword)
                    Swal.fire({title:"¡La clave se cambio con exito!", icon:"success"})
                    setPassword("")
                    setNewPassword("")
                    setConfirmPassword("")
                    navigate("/userPanel")
                } catch (error) {
                    Swal.fire({title:"Hubo un error al cambiar la clave, por favor vuelva a intentarlo.", icon:"error"})
                    setPassword("")
                    setNewPassword("")
                    setConfirmPassword("")
                }
            }else{
                Swal.fire({title:"Las claves no coinciden.", icon:"warning"})
                setNewPassword("")
                setConfirmPassword("")
            }
        }else{
            Swal.fire({title:"La clave debe contener al menos 8 digitos.", icon:"warning"})
        }
    };

    const [password, setPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");

  return (
    <>
        <HeaderUserLogin />
        <h1 className="title">
            Actualizar Clave
        </h1>
        <div className="login-container">
            <div className="card">
                <label htmlFor="clave-actual">Clave Actual</label>
                <div className="input-group">
                    <input
                        type="password"
                        id="clave-actual"
                        className="input-field"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        onKeyDown={(e) => validateCharacter(e)}
                    />
                </div>
                <label htmlFor="Nueva-Clave">Nueva Clave</label>
                <div className="input-group m0">
                    <input
                        type="password"
                        id="Nueva-Clave"
                        className="input-field"
                        value={newPassword}
                        onChange={(e) => setNewPassword(e.target.value)}
                        onKeyDown={(e) => validateCharacter(e)}
                    />
                </div>
                <p className="Message"> * La clave nueva puede contener solo letras y números. </p>

                <label htmlFor="Repita-Nueva-Clave">Repetí la Nueva Clave</label>
                <div className="input-group">
                    <input
                        type="password"
                        id="Repita-Nueva-Clave"
                        className="input-field"
                        value={confirmPassword}
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        onKeyDown={(e) => validateCharacter(e)}
                    />
                </div>

                <div>
                    <button className="button-link" onClick={async () => {await handlerChangePasswordButton()}}>
                        Cambiar Clave
                    </button>
                </div>
                <div className="bottom-image">
                    <img src={imageLine} alt="line" className="bottom-image" />
                </div>
            </div>
            <button className="btn-secondary" onClick={handlerBackButton}>Volver atrás</button>

            <FooterUserLogin />
        </div>
    </>
  )
}

export default ChangePass
