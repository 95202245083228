import React, { useState, useEffect } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { getRoomById } from "../../services/rooms/getRoomById";
import { updateRoomById } from "../../services/rooms/updateRoomById";
import Header from "../Shared/Header";
import Footer from "../Shared/Footer";
import "./EditRoom.css";

const EditRoom = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  const roomToEdit = location.state.roomData;
  const [room, setRoom] = useState({
    name: "",
    active: false,
    capacity: 0,
    price: 0,
  });

  useEffect(() => {
    const fetchRoomData = async () => {
      try {
        const roomData = await getRoomById(id);
        setRoom(roomData);
      } catch (error) {
        console.error("Error al obtener la sala", error);
      }
    };
    if (roomToEdit) {
      setRoom(roomToEdit);
    } else {
      fetchRoomData();
    }
  }, [id, roomToEdit]);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
  
    const newValue = type === 'checkbox' ? checked : value;
  
    setRoom({ ...room, [name]: newValue });
  };

  const handleUpdate = async () => {
    if (!window.confirm('¿Estás seguro de que queres guardar los cambios?')) return
    try {
      await updateRoomById(room);
      navigate("/rooms");
    } catch (error) {
      console.error("Error al actualizar la sala", error);
    }
  };

  return (
    <>
      <Header />
      <div className="edit-room-container">
        <h2 className="title">Editar Sala</h2>
        <div className="edit-room-form">
          <div className="form-group">
            <label>Nombre:</label>
            <input
              type="text"
              name="name"
              value={room.name}
              onChange={handleInputChange}
            />
          </div>
          <div className="form-group">
            <label>Capacidad:</label>
            <input
              type="number"
              name="capacity"
              value={room.capacity}
              onChange={handleInputChange}
            />
          </div>
          <div className="form-group">
            <label>Activa:</label>
            <input
              type="checkbox"
              name="active"
              checked={room.active}
              onChange={handleInputChange}
            />
          </div>
          <div className="group-buttons">
            <button className='btn-primary' onClick={handleUpdate} >Guardar Cambios</button>
            <button className='btn-delete' onClick={() => navigate(-1)} >Cancelar</button>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default EditRoom;
