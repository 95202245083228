import "./ideas.css";
import { messages } from "./messages-ideas";

export const IdeaItem = () => {
  return (
    <>
      {
        messages.map((cards) => 
        <div className="col-md-6 col-lg-4 ideas">
          <div className='iconContainer'>
            <img src={cards.icon}></img>
          </div>

          <div className="card-description">
            <h5>{cards.title}</h5>
            <p>{cards.text}</p>
          </div>
        </div> )
      }
    </>
  )
}
