import React from 'react';
import imageFundation from '../../img/fundacion.png';
import { ContactButtons } from '../Home/Footer/ContactButtons';

const Footer = () => {
  return (
    <>
      <footer className="footer">
        <img src={imageFundation} alt="fundation" className="footer-image" />
      </footer>
      <ContactButtons />
    </>
  );
};

export default Footer;