import React, { useState, useEffect } from "react";
import Header from "../Shared/Header";
import Footer from "../Shared/Footer";
import calendar from "../../img/calendar.png";
import hall from "../../img/hall-big.png";
import chair from "../../img/chair-big.png";
import {Modal, ModalHeader, ModalBody, ModalFooter} from "reactstrap"
import { useNavigate } from "react-router-dom";
import { getBookingsByDate } from "../../services/bookings/getBookingsByDate"; 
import { fixDateOffset } from "../../helpers/utils";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./AdminBookings.css";
import AssistanceTable from "./AssistanceTable";
import { holidays } from "../../helpers/holidays";
import { addDays } from "date-fns";
const QR_FUNCTIONALITY = process.env.REACT_APP_QR_FUNCTIONALITY === "true";

const AdminBookings = () => {
  const [bookings, setBookings] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [modalOpen, setModalOpen] = useState(false)
  const [selectedBooking, setSelectedBooking] = useState({})

  const navigate = useNavigate();

  const openModal =(booking)=>{
    setSelectedBooking(booking)
    setModalOpen(true)
  }

  const closeModal =()=>{
    setModalOpen(false)
    setSelectedBooking({})
  }

  useEffect(() => {
    const fetchBookingsByDate = async () => {
      try {
        setLoading(true);
        const formattedDate = selectedDate.toISOString().split("T")[0];
        const data = await getBookingsByDate(formattedDate);


        if (data.length === 0) {
          setErrorMessage("No hay reservas para la fecha seleccionada");
          setBookings([]); 
        } else {
          setBookings(data);
          setErrorMessage(null); 
        }
      } catch (error) {

        if (error.response && error.response.status === 404) {
          setBookings([]); 
          setErrorMessage("No hay reservas para la fecha seleccionada");
        } else {
          setErrorMessage("Error al traer las reservas");
        }
      } finally {
        setLoading(false);
      }
    };

    fetchBookingsByDate();
  }, [selectedDate]);

  const handleBackButtonClick = () => {
    navigate(-1);
  };

  const handleChange = (date) => {
    setSelectedDate(date);
  };

  return (
    <>
      <Header />
      <Modal size="l" isOpen={modalOpen}>
        <ModalHeader className="center">
          Asistentes a la reserva
        </ModalHeader>
        <ModalBody style={{height: "50vh", }}>
          <AssistanceTable booking={selectedBooking} />
        </ModalBody>
        <ModalFooter className="center-row">
          <button onClick={closeModal} className="btn-secondary btn-small">Aceptar</button>
        </ModalFooter>
      </Modal>
      <div className="center">
        <div className="full-width-background">
          <img className="calendar-image" src={calendar} alt="Calendario" />
          <h1>Ver reservas</h1>
          <p>Buscar por fecha</p>
          <DatePicker
            selected={selectedDate}
            onChange={handleChange}
            excludeDates={holidays.map(holiday => addDays(new Date(holiday.date), 1))}
            dateFormat="dd/MM/yyyy"
            filterDate={(date) => date.getDay() !== 0 && date.getDay() !== 6}
            locale="es"
            minDate={new Date()}
            className="rounded-select"
            todayButton="Hoy"
          />
        </div>

        <div className="cards-adminBookings-box">
          {loading ? (
            <p>Cargando...</p>
          ) : (
            <>
              {bookings.length > 0 ? (
                bookings.map((booking, index) => (
                  <div key={index} className="card card--booking row">
                    <div className="center col-md-2 col-sm-12">
                      {booking.room.type === "room" ? (
                        <img width={90} height={90} src={hall} alt="Sala" />
                      ) : (
                        <img width={90} height={90} src={chair} alt="Puesto" />
                      )}
                    </div>
                    <div className="col-md-8">
                      <p>
                        {`${booking.user.name} ${booking.user.lastname} (${booking.user.username}) reservó: `}
                        <span style={{ color: "#00B7DD" }}>{booking.room.name}</span>
                      </p>
                      <p>{`Para el día ${fixDateOffset(
                        new Date(booking.date)
                      ).toLocaleDateString("es-ES", {
                        year: "numeric",
                        month: "long",
                        day: "numeric",
                      })} de ${booking.startHour}hs a ${booking.endHour}hs.`}</p>
                    </div>
                    {QR_FUNCTIONALITY && <div className="col-md-2" style={{ color: "#00B7DD", cursor: 'pointer'}} onClick={() => openModal(booking)}>Ver asistencia</div>}
                  </div>
                  
                ))
              ) : (
                <p>{errorMessage || "No hay reservas para la fecha seleccionada"}</p>
              )}
            </>
          )}
        </div>
        <button className="btn-secondary" onClick={handleBackButtonClick}>
          Volver atrás
        </button>
      </div>

      <Footer />
    </>
  );
};

export default AdminBookings;
