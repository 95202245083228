import React from "react";
import Header from "../Header/Header";
import "./main.css";
import { messages } from "./messages-main";
import ReactPlayer from "react-player";

const Main =()=>{
    return(
        <div className="h-auto">
            <Header/>
            <main className="main">
                <div className="main-content">
                    <b className="bullet">{messages.main.bullet}</b> <b>{messages.main.pretitle}</b>
                    <h2>
                        {messages.main.title}
                    </h2>
                    <h6>
                        {messages.main.subtitle}
                    </h6>
                    <p>
                        {messages.main.description}
                    </p>
                    <p>
                        {messages.main.description2}
                    </p>
                </div>
            </main>
            <div className="video-container">
                <b className="bullet">{messages.main.bullet}</b> <b>{messages.video.pretitle}</b>
                <h2>
                    {messages.video.title}
                </h2>
                <ReactPlayer
                    url="https://www.youtube.com/watch?v=iCJcxRabUBo&ab_channel=ProgramaEureka"
                    width="100%"
                    height="100%"
                    controls
                    playing
                    volume="0.3"
                />
            </div>
        </div>
    )
}

export default Main