import React from 'react'
import RouterApp from "./router/Router";

function App() {
  
  return (
    <>
      <RouterApp />
    </>
  );
}

export default App;