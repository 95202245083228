import FaqRow from "./FaqRow";
import fundacion from "../../../img/fundacion.png";
import Footer from "../../Shared/Footer"

const FaqContainer = () => {
  return (
    <>
      <h1 className="mb-4">Preguntas Frecuentes</h1>
      <div className="row">
        <FaqRow
          question="¿Quiénes pueden presentar su idea o proyecto?"
          answer="Está orientado a jóvenes entre 16 y 35 años que pretendan desarrollar una Idea o Proyecto en la provincia de Formosa, y formar parte de la Red Eureka, donde se compartirán experiencias y se gestionarán actividades, charlas, formaciones e incluso la posibilidad de acceso a fuentes de financiamiento en el marco de un Programa de Formación y Desarrollo de Emprendedores y Proyectos de Negocios."
        />
        <FaqRow
          question="¿Qué tipo de proyectos pueden ser parte de esta iniciativa?"
          answer="Deberán apuntar al desarrollo de iniciativas o emprendimientos, ya sean productivos, comerciales, o de cualquier actividad."
        />
        <FaqRow
          question="¿Qué tipo de estudios debo tener?"
          answer="No hay restricciones en el nivel de estudios requerido."
        />
        <FaqRow
          question="¿Se debe trabajar sólo o se puede construir un equipo?"
          answer="Los proyectos podrán presentarse a título individual o por equipos de hasta un máximo de 4 participantes por equipo."
        />
        <FaqRow
          question="¿Debo tener alguna experiencia laboral?"
          answer="No es requerida, aunque sí recomendable."
        />
        <FaqRow
          question="¿Qué datos debo presentar?"
          answer="Están detallados en el Formulario de Inscripción: Nombre, DNI, Email, Teléfono Celular y Localidad de Residencia."
        />
        <FaqRow
          question="¿Tiene costo el Programa?"
          answer="No, no tiene ningún costo económico. Sólo tu inversión de tiempo, la pasión y compromiso."
        />
        <FaqRow
          question="¿Qué puedo esperar que me dé el programa?"
          answer="EUREKA posibilitará a los emprendedores el acceso a formación, capacitación y apoyo técnico, e incluso a fuentes de financiamiento, a partir de la conformación de una red de emprendedores en el marco de un Programa de Ideas y Proyectos de Negocios."
        />
      </div>
      <Footer className="center"/>
    </>
  );
};

export default FaqContainer;
