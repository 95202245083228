import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from '../eureka/components/Login/Login';
import UserPanel from '../eureka/components/Login/UserPanel/UserPanel';
import Booking from '../eureka/components/Booking/Booking';
import ShowBookings from '../eureka/components/Booking/ShowBookings';
import CancelBooking from '../eureka/components/Booking/CancelBooking';
import ConfirmBooking from '../eureka/components/Booking/ConfirmBooking';
import SuccessBooking from '../eureka/components/Booking/SuccessBooking';
import NotAvailable from '../eureka/components/Booking/NotAvailable';
import Rooms from '../eureka/components/Rooms/Rooms';
import NewRoom from '../eureka/components/Rooms/NewRoom';
import RoomSaved from '../eureka/components/Rooms/RoomSaved';
import ChangePass from '../eureka/components/Login/ChangePass';
import Home from '../eureka/components/Home/Home';
import ConfirmCancelBooking from '../eureka/components/Booking/ConfirmCancelBooking';
import ErrorMessage from '../eureka/components/Shared/ErrorMessage';
import AdminUsers from '../eureka/components/AdminUsers/AdminUsers';
import Register from '../eureka/components/Login/Register';
import SuccessRegister from '../eureka/components/Login/SuccessRegister';
import Scanner from '../eureka/components/Scanner/Scanner';
import SuccessEntry from '../eureka/components/Scanner/SuccessEntry';
import RegisterEntry from '../eureka/components/Scanner/RegisterEntry';
// ... (importaciones y otros componentes)

import { ProtectedRoute } from '../eureka/components/Shared/ProtectedRoute';
import { UserProvider } from '../eureka/context/UserContext';
import EditRoom from '../eureka/components/Rooms/EditRoom';
import SuccessRoom from '../eureka/components/Rooms/SuccessRoom';
import AdminBookings from '../eureka/components/AdminBookings/AdminBookings';
import VerifyDNI from '../eureka/components/Scanner/VerifyDNI';

const RouterApp = () => {
  return (
    <UserProvider>
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/login" element={<Login />} />
          <Route path="/userPanel" element={
            <ProtectedRoute>
              <UserPanel />
            </ProtectedRoute>} />
            <Route path="/booking" element={
              <ProtectedRoute>
                <Booking />
              </ProtectedRoute>
            } />
          <Route path="/ShowBookings" element={
            <ProtectedRoute>
              <ShowBookings />
            </ProtectedRoute>} />
          <Route path="/CancelBooking" element={
            <ProtectedRoute>
              <CancelBooking />
            </ProtectedRoute>} />
            <Route path="/confirmBooking" element={
            <ProtectedRoute>
              <ConfirmBooking/>
            </ProtectedRoute>} />
            <Route path='/adminBookings' element={
            <ProtectedRoute>
              <AdminBookings/>
            </ProtectedRoute>} />
          <Route path='/successBooking' element={
            <ProtectedRoute>
              <SuccessBooking/>
            </ProtectedRoute>} />
          <Route path='/notAvailable' element={
            <ProtectedRoute>
              <NotAvailable/>
            </ProtectedRoute>} />
          <Route path='/changepassword' element={
            <ProtectedRoute>
              <ChangePass/>
            </ProtectedRoute>} />
            <Route path="/rooms" element={
            <ProtectedRoute>
              <Rooms/>
            </ProtectedRoute>} />
            <Route path="/newRoom" element={
            <ProtectedRoute>
              <NewRoom/>
            </ProtectedRoute>} />
            <Route path="/editRoom/:id" element={
            <ProtectedRoute>
              <EditRoom/>
            </ProtectedRoute>} />
            <Route path="/roomSaved" element={
            <ProtectedRoute>
              <RoomSaved/>
            </ProtectedRoute>} />
            <Route path="/successRoom" element={
            <ProtectedRoute>
              <SuccessRoom/>
            </ProtectedRoute>} />
            <Route path='/confirmCancel' element={
            <ProtectedRoute>
              <ConfirmCancelBooking/>
            </ProtectedRoute>} />
            <Route path='/bookingError' element={
            <ProtectedRoute>
              <ErrorMessage/>
            </ProtectedRoute>} />
            <Route path="/adminUsers" element={
            <ProtectedRoute>
              <AdminUsers />
            </ProtectedRoute>} />
            <Route path="/register" element={<Register />} />
            <Route path="/successRegister" element={
            <ProtectedRoute>
              <SuccessRegister />
            </ProtectedRoute>} />
            <Route path="/scanner" element={
            <ProtectedRoute>
              <Scanner />
            </ProtectedRoute>} />
            <Route path="/verifyDNI" element={
            <ProtectedRoute>
              <VerifyDNI />
            </ProtectedRoute>} />
            <Route path="/successEntry" element={
            <ProtectedRoute>
              <SuccessEntry />
            </ProtectedRoute>} />
            <Route path="/registerEntry" element={<RegisterEntry />} />
        </Routes>
      </Router>
    </UserProvider>
  );
};

export default RouterApp;
