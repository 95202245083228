import axios from 'axios';
const REACT_APP_BOOKINGS_API_URL = process.env.REACT_APP_BOOKINGS_API_URL

export const updateRoomById = async (room) => {
  const url = `${REACT_APP_BOOKINGS_API_URL}/rooms/${room.id}`
  const headers = {
    'Authorization': `Bearer ${await localStorage.getItem('access_token')}`,
    'Content-Type': 'application/json'
  };
  try {
    const response = await axios.put(url, {...room}, {headers: headers})
    return response.data
  } catch (error) {
    throw error
  }
}

