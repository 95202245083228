import axios from 'axios';
const REACT_APP_LOGIN_API_URL = process.env.REACT_APP_LOGIN_API_URL

export const getUserById = async (id) => {
  const url = `${REACT_APP_LOGIN_API_URL}/users/${id}`
  const headers = {
    'Authorization': `Bearer ${await localStorage.getItem('access_token')}`,
    'Content-Type': 'application/json'
  };
  try {
    const response = await axios.get(url, {headers: headers})
    return response.data
  } catch (error) {
    throw error
  }
}