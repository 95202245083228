import Main from "../../../eureka/components/Home/Main/Main";
import Services from "../../../eureka/components/Home/Services/Services"
import FaqContainer from "../../../eureka/components/Home/Faq/FaqContainer"
import PhotosDescriptionContainer from "../../../eureka/components/Home/Photos/PhotosDescriptionContainer";
import '../../../index.css';
import IdeasContainer from "../../../eureka/components/Home/Ideas/IdeasContainer";
import Clips from "../../../eureka/components/Home/ClipsSection/Clips"

function Home() {
  return (
    <div className="App">
      <Main/>
      <Services/>
      <IdeasContainer />
      <Clips/>
      <PhotosDescriptionContainer />
      <FaqContainer />
    </div>
  );
}

export default Home;