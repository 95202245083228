import axios from 'axios';
const REACT_APP_LOGIN_API_URL = process.env.REACT_APP_LOGIN_API_URL

export const createUser = async (user) => {
    const url = `${REACT_APP_LOGIN_API_URL}/register`
    const headers = {
        'Content-Type': 'application/json'
    };
    try {
        const response = await axios.post(url, {...user}, {headers: headers})
        return response
    } catch (error) {
        throw error
    }
}