import React from 'react';
import Header from '../Shared/Header';
import Footer from '../Shared/Footer';
import Cancel from "../../img/fail.png";
import { useNavigate, useLocation } from 'react-router-dom';

const ErrorMessage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const errorMessage = location.state.message

  const handlerBackButtonClick = () => {
        navigate(-1); 
    };

  return (
    <>
        <Header/>
        <div className='center text-container'>
            <img src={Cancel} alt="Cancel Icon" />
            <h5 className='delete'>¡Lo lamentamos! <br></br> Se ha producido un error</h5>
            <h6>{errorMessage}</h6>
            <button className='btn-secondary' onClick={handlerBackButtonClick}>Volver</button>
        </div>
        <Footer/>
    </>
  )
}

export default ErrorMessage
