import React, { useEffect } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import "../Booking/booking.css";
import HeaderUserLogin from "../Shared/Header";
import FooterUserLogin from "../Shared/Footer";
import "react-datepicker/dist/react-datepicker.css";
import successImage from "../../img/ic_confirm.png";
import timeImage from "../../img/ic_time.png";

const SuccessEntry = () => {
    const navigate = useNavigate();
    const location = useLocation();
  
    useEffect(() => {
      const redirectTimeout = setTimeout(() => {
        navigate("/scanner");
      }, 6000);
      return () => clearTimeout(redirectTimeout);
    }, []);
  
  return (
        <div className="login-container">
            <HeaderUserLogin />
            <h2 className="title">Registro de asistentes</h2>
            <img className="success-icon" src={successImage} alt="Success Icon" />
            <h2 className="title">
                <span style={{ color: "#00B7DD" }}>¡Registro Exitoso!</span>
            </h2>
            <h5>
                El usuario ya se encuentra habilitado para ingresar
            </h5>
            <div className="time-container">
            <img className="time-icon" src={timeImage} alt="Time Icon" />
            <label>
                Te redireccionaremos <br/> automáticamente a la <br/> pantalla de escaner
            </label>
            </div>
            <FooterUserLogin />
        </div>
  )
}

export default SuccessEntry