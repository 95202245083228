import React, { useState } from "react";
import "./scanner.css";
import HeaderUserLogin from "../Shared/Header";
import FooterUserLogin from "../Shared/Footer";
import vectorImage from "../../img/addRegister.png";
import  { useLocation, useNavigate } from 'react-router-dom'
import { confirmAssistance } from '../../services/bookings/confirmAssistance';
import Swal from "sweetalert2";

const RegisterEntry = () => {
    const navigate = useNavigate()
    const location = useLocation();
    const assistanceUser = location.state?.assistanceUser;
    const bookingId = location.state?.bookingId;
    const dni = location.state?.dni;

    const backHomeButton =()=>{
      navigate("/scanner")
    }

    const [showMessage, setShowMessage] = useState(false)
    const [errMessage, setErrMessage] = useState("")

    const validateNumber = (e) => {
        if(!/^([0-9]+)$/i.test(e.key)){
            e.preventDefault()
        }
    }

    const validateName = (e) => {
        if(!/^([a-z ]+)$/i.test(e.key)){
            e.preventDefault()
        }
    }

    const [newGuest, setNewGuest] = useState({name: assistanceUser?.name, lastname:assistanceUser?.lastname, dni: dni, mail:assistanceUser?.mail})

    const disabledButton =()=>{
        return !newGuest.name || !newGuest.lastname || !newGuest.dni || !newGuest.mail
    }

    const handleChange =(e)=>{
        const { target } = e;
        const { name, value } = target;

        const newValue = value.trim()

        const newValues = {
            ...newGuest,
            [name]: newValue,
        };
        setNewGuest(newValues);
    }

    const handleCreateGuest = async () => {
        setErrMessage("")
        setShowMessage(false)
        if (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/i.test(newGuest.mail))) {
            setErrMessage("El correo electrónico no es válido")
            setShowMessage(true)
            return
        }

        try {
            await confirmAssistance(bookingId, newGuest)
            navigate("/successEntry")
        } catch (error) {
            if (error.response.status === 409) {
                Swal.fire(error.response.data.message);
              } else {
                Swal.fire({title:"Hubo un error al confirmar el registro al evento, por favor intente nuevamente", icon:"error"})
            }
        }
    }

  return (
    <>
        <HeaderUserLogin/>
        <main className='main-gradient'>
            <img  src={vectorImage} alt="User" />
            <h2 className="title">
                Completa el formulario para registrar al asistente  
            </h2>
            <button onClick={backHomeButton} className="btn-secondary">Volver al escaner</button>
        </main>
        <div className='center'>
            <div className="input-container">
                <p className="subtitle">Nombre</p>
                <input
                className="rounded-select"
                type="text"
                name="name"
                value={newGuest.name}
                onChange={handleChange}
                onKeyPress={(e) => validateName(e)}
                disabled={assistanceUser?.name}
                />
            </div>
            <div className="input-container">
                <p className="subtitle">Apellido</p>
                <input
                className="rounded-select"
                type="text"
                name="lastname"
                value={newGuest.lastname}
                onChange={handleChange}
                onKeyPress={(e) => validateName(e)}
                disabled={assistanceUser?.name}
                />
            </div>
            <div className="input-container">
                <p className="subtitle">DNI</p>
                <input
                className="rounded-select"
                type="text"
                maxLength="8"
                name="dni"
                value={newGuest.dni}
                onChange={handleChange}
                onKeyPress={(e) => validateNumber(e)}
                disabled
                />
            </div>
            <div className="input-container">
                <p className="subtitle">Correo Electrónico</p>
                <input
                className="rounded-select"
                type="text"
                name="mail"
                value={newGuest.mail}
                onChange={handleChange}
                disabled={assistanceUser?.name}
                />
            </div>
           
            {showMessage && <h6 className={`pass-error-message`}>{errMessage}</h6>}
            <div className='btn-box'>
                <button disabled={disabledButton()} onClick={handleCreateGuest}  className="btn-primary">Registrar</button>
            </div>
        </div>
        <FooterUserLogin/>
    </>
  )
}

export default RegisterEntry