export const holidays = [
  { date: "2024-05-01", holidayName: "Dia del Trabajador y Trabajadora" },
  { date: "2024-05-25", holidayName: "Dia de la Revolucion de Mayo" },
  { date: "2024-06-17", holidayName: "Paso a la Inmortalidad del Gral. Martin M. de Guemes" },
  { date: "2024-06-20", holidayName: "Paso a la inmortalidad del Gral. M. Belgrano" },
  { date: "2024-06-21", holidayName: "Feriado con fines turisticos" },
  { date: "2024-06-28", holidayName: "Dia de la Provincializacion de Formosa" },
  { date: "2024-07-09", holidayName: "Dia de la Independencia" },
  { date: "2024-07-16", holidayName: "Dia de la Virgen del Carmen, patrona de Formosa" },
  { date: "2024-10-11", holidayName: "Feriado con fines turisticos (Dia del Respeto a la Diversidad Cultural)" },
  { date: "2024-11-06", holidayName: "Dia del Bancario y Bancaria" },
  { date: "2024-11-18", holidayName: "Dia de la Soberania Nacional" },
  { date: "2024-12-08", holidayName: "Dia de la Inmaculada Concepción de María" },
  { date: "2024-12-25", holidayName: "Navidad" },
]