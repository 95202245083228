import "./photoDescription.css";

const PhotoDescriptionRow = ({ photo, title, description, span }) => {
  return (
    <div className="row d-flex align-items-center mb-5 photos">
      <div className="col-sm-12 col-lg-6">
        <img src={photo} alt="foto-1" />
      </div>
      <div className="col-sm-12 col-lg-6">
        <div className="photoContainer">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <b className="bullet">● </b>
            <b
              style={{
                marginLeft: "5px",
              }}
            >
              {span}
            </b>
          </div>
          <h3 style={{ width: "100%", fontSize: "30px" }}>{title}</h3>
          <p style={{ fontSize: "18px" }}>{description}</p>
        </div>
      </div>
    </div>
  );
};

export default PhotoDescriptionRow;
