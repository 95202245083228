import React, { useState, useContext } from "react";
import QRCode from 'qrcode.react';
import logoQR from "./../../img/logo.png";
import Header from '../Shared/Header';
import Footer from '../Shared/Footer';
import calendar from "../../img/calendar.png";
import hall from "../../img/hall-big.png";
import chair from "../../img/chair-big.png";
import { useLocation, useNavigate } from 'react-router-dom';
import { fixDateOffset } from '../../helpers/utils';
import { Modal, ModalBody } from 'reactstrap';
import border from "./../../img/qr-border.png";
const QR_FUNCTIONALITY = process.env.REACT_APP_QR_FUNCTIONALITY === "true";

async function shareCanvasAsImage() {
    // Get canvas as dataURL
    const dataUrl = document.getElementById('booking-qrcode').toDataURL()
  
    // Convert dataUrl into blob using browser fetch API
    const blob = await (await fetch(dataUrl)).blob()
  
    // Create file form the blob
    const image = new File([blob], 'MiReserva.png', { type: blob.type })
  
    // Check if the device is able to share these files then open share dialog
    if (navigator.canShare && navigator.canShare({ files: [image] })) {
      try {
        await navigator.share({
            files: [
                new File([blob], 'Reserva.png', {
                type: blob.type,
                }),
            ],
        })
      } catch (error) {
        console.log('Sharing failed!', error)
      }
    } else {
        alert('Este dispositivo no soporta compartir archivos.')
        console.log('This device does not support sharing files.')
    }
  }

const today = new Date(Date.now())
const todayFormatted = today.toISOString().split('T')[0];
const actualHour = ('0'+ today.getHours()).slice(-2)

const ShowBookings = () => {
    const location = useLocation();
    const bookings = location.state?.bookings;
    const navigate = useNavigate();
    const [selectedBooking, setSelectedBooking] = useState({});

    const handleDisableCancelButton = (booking) => {
        if (booking.date < todayFormatted) return true
        if (booking.date === todayFormatted && booking.startHour.split(':')[0] <= actualHour) {
            return true
        }
        return false
    }

    const handleBackButtonClick = () => {
        navigate(-1);
    };

    const handlerCancelButton = (booking) => {
        navigate("/confirmCancel", { state: { booking } });
    };

    const handlerEditButton = (booking) => {
        navigate(`/booking`, { state: { booking } });
    };

    const modalBackButton = async () => {
        setModal(false)
    };

    const [modal, setModal] = useState(false)

    const showModal =(booking)=>{
        setSelectedBooking(booking)
        setModal(true)
    }

    return (
        <>
            <Header />
            <div className='center'>
                <main className="main-gradient">
                    <img className='calendar-image' src={calendar} alt="Calendario" />
                    <h1>Mis reservaciones en Eureka</h1>
                </main>
                {bookings.map(booking => (
                    <div key={booking.id} className='card card--booking row'>
                        <div className='col-md-2 col-sm-12'>
                            {
                                booking.room.type === "room" ?
                                    <img width={90} height={90} src={hall} alt="Sala" />
                               :
                                    <img width={90} height={90} src={chair} alt="Puesto" />
                                
                            }
                        </div>
                        <div className='center col-md-8 col-sm-12 booking-data'>
                            <h4><b>Reservaste {booking.room.name}</b></h4>
                            <h6>Para el día {fixDateOffset(new Date(booking.date)).toLocaleDateString('es-ES', { year: "numeric", month: "long", day: "numeric" })} de {booking.startHour}hs a {booking.endHour}hs. </h6>
                            {QR_FUNCTIONALITY && <h6 className="show-qr" onClick={() => showModal(booking)}>Ver Código QR</h6>}
                        </div>
                        <div className='center col-md-2 col-sm-12'>
                            { !handleDisableCancelButton(booking) && <button className='btn-primary btn-small' onClick={() => handlerEditButton(booking)}>Editar</button>}
                            { !handleDisableCancelButton(booking) && <button className='btn-delete btn-small'  onClick={()=> handlerCancelButton(booking)}>Cancelar</button>}
                        </div>
                        <Modal className='qr-modal' isOpen={modal}>
                            <h2><b>{selectedBooking.room?.name}</b></h2>
                            <h6>{fixDateOffset(new Date(selectedBooking?.date)).toLocaleDateString('es-ES', { year: "numeric", month: "long", day: "numeric" })} de {selectedBooking?.startHour}hs a {selectedBooking?.endHour}hs. </h6>
                            <ModalBody>
                                <div className="center">
                                    <img className="qr-border" src={border} alt="Border" />
                                    <QRCode
                                        id="booking-qrcode"
                                        style={{ 
                                            width: "60%",
                                            height: "60%"
                                        }}
                                        size={512}
                                        className="qr-img" 
                                        value={JSON.stringify({
                                            id: selectedBooking?.id,
                                            owner: selectedBooking.user?.dni,
                                        
                                        })} 
                                        renderAs="canvas"
                                        imageSettings={{
                                            src: logoQR,
                                            height: 70,
                                            width: 140,
                                            excavate: true,
                                        }}
                                    />
                                </div>
                            </ModalBody>
                            <div className='buttons-row'>
                                <button onClick={modalBackButton} className='btn-secondary btn-small'>Cerrar</button>
                                <button className='btn-primary btn-small' onClick={async () => await shareCanvasAsImage()}>Compartir</button>
                            </div>
                        </Modal>
                    </div>
                ))}
                <button className='btn-secondary' onClick={handleBackButtonClick}>Volver atrás</button>
            </div>
            <Footer />
        </>
    );
};

export default ShowBookings;