import axios from 'axios';
const REACT_APP_BOOKINGS_API_URL = process.env.REACT_APP_BOOKINGS_API_URL

export const getRooms = async () => {
  const url = `${REACT_APP_BOOKINGS_API_URL}/rooms`
  const headers = {
    'Authorization': `Bearer ${await localStorage.getItem('access_token')}`,
    'Content-Type': 'application/json'
  };
  try {
    const response = await axios.get(url, {headers: headers})
    return response.data
  } catch (error) {
    throw error
  }
}

