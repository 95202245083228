import React, { useState, useContext } from "react";
import { jwtDecode } from "jwt-decode";
import  { Navigate, useNavigate } from 'react-router-dom'
import { UserContext } from "../../context/UserContext";
import imageLine from "../../img/image-bg-1.png";
import "./login.css";
import HeaderUserLogin from "../Shared/Header";
import FooterUserLogin from "../Shared/Footer";
import { validateSession } from "../../helpers/utils";
import { login } from "../../services/users/login";
import { Spinner } from "reactstrap";


const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const { setUser } = useContext(UserContext);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate()
  const handlerBackButton =()=>{
    navigate("/")
  }

  const RegisterButton =()=>{
    navigate("/register")
  }



  const handleLogin = async () => {
    setError(null)
    setLoading(true)
    try {
      const response = await login(email, password);
      if (response.status === 200) {
        localStorage.setItem("access_token", response.data.access_token);
        localStorage.setItem("token_expires", response.data.expires);
        const decoded = jwtDecode(response.data.access_token);
        const userDecoded = {
          id: decoded.id,
          role: decoded.role,
          username: decoded.username,
          name: decoded.name,
          lastname: decoded.lastname,
          dni: decoded.dni,
          membership: decoded.membership,
          project: decoded.project,
          origin: decoded.origin,
        }
        localStorage.setItem("user", JSON.stringify(userDecoded));
        setUser({...userDecoded})
        if (response.data.isChangePassword) {
          window.location.replace('/changePassword')
        } else {
          window.location.replace('/userPanel')
        }
      } else {
        setLoading(false)
        throw new Error("Error al intentar acceder. Por favor, intentalo de nuevo.");
      }
    } catch (error) {
      if (error.response.status === 401) {
        setLoading(false)
        setError("Credenciales incorrectas. Por favor, intentalo de nuevo.");
      } else {
        setLoading(false)
        console.error("Error al hacer la solicitud a la API:", error);
        setError("Error al intentar acceder. Por favor, intentalo de nuevo.");
      }
    }
    
  };

  if (validateSession()) {
    return <Navigate to='/userPanel' />
  }

  return (
    <>
      <HeaderUserLogin />
      <h2 className="login-title">
        Accede a tu panel de <span className="secondary-color">socios</span>
      </h2>
      <div className="login-container">
        <div className="card">
          <h3 className="login-title">Login</h3>
          <label htmlFor="email">Correo Electrónico</label>
          <div className="input-group">
            <input
              type="email"
              id="email"
              className="input-field"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <label htmlFor="password">Clave</label>
          <div className="input-group m0">
            <input
              type="password"
              id="password"
              className="input-field"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              onKeyDown={(e) => {e.key === 'Enter' && handleLogin()}}
            />
          </div>
          <p className="Message"> Si no la generaste, tu clave es tu número de DNI </p>
          {error && <div className="error-message">{error}</div>}
          <div>
            {loading ? <Spinner color="primary" /> : <button onClick={handleLogin} className="button-link">Acceder</button>}
          </div>
          <div>
            <button onClick={RegisterButton} className="btn-secondary">Registrarse</button>
          </div>
          <div className="bottom-image">
            <img src={imageLine} alt="line" className="bottom-image" />
          </div>
        </div>
        <button className="btn-secondary" onClick={handlerBackButton}>Volver a Home</button>
        <FooterUserLogin />
      </div>
    </>
  );
};

export default Login;
