export const messages = {
    main: {
        bullet: "●",
        pretitle: "Potencia tu proyecto",
        title: "¿Tenés un sueño, una pasión o una idea?",
        subtitle: "¿Querés transformarte en un agente de cambio de tu propio desarrollo?",
        description: "Te invitamos a que nos presentes tu idea, iniciativa o proyecto, para formar parte de la Red de Jóvenes Emprendedores EUREKA y convertirte en protagonista del desarrollo de la Provincia de Formosa.",
        description2: "Desde EUREKA te proponemos un programa que te acompañará a desarrollar tus ideas innovadoras o potenciar las existentes para convertirlas en iniciativas reales. "
    },

    video: {
        pretitle: "Una idea del Banco de Formosa",
        title: "Imaginate si emprendemos",
    }
}