import React, { useContext, useState } from "react";
import HeaderUserLogin from "../Shared/Header";
import FooterUserLogin from "../Shared/Footer";
import okImage from '../../img/ic_ok.png';
import { useNavigate, useLocation } from 'react-router-dom';
import { createBooking } from "../../services/bookings/createBooking";
import { updateBookingById } from "../../services/bookings/updateBookingById";
import { UserContext } from "../../context/UserContext";
import { fixDateOffset, handleLocalStorageCredits } from "../../helpers/utils";
import calendar from '../../img/ic_vector_ok.png';
import {Modal, ModalHeader, ModalBody, ModalFooter} from "reactstrap"
import unCheck from '../../img/unCheck.png';
import checked from '../../img/checked.png';
import { freeBookingUserTypes } from "../../dictionaries/users";

const ConfirmBooking = () => {
  const { user, setUser } = useContext(UserContext);
  const location = useLocation();
  const booking = location.state.booking;
  const previousPrice = location.state.previousPrice;
  const navigate = useNavigate();

  const handleConfirmButtonClick = async () => {
    try {
      const { data } = await createBooking(booking)
      const newCredits = user.membership.credits - booking.bookingPrice
      setUser(prevState => (
        {...prevState, 
          membership: {
            ...prevState.membership, 
            credits: newCredits
          }
        }
      ))
      handleLocalStorageCredits(newCredits)
      navigate("/successBooking", {state: {booking: data} });
    } catch (error) {
      if(error.response.status === 404){
        navigate("/notAvailable", {state: {booking} });
      } else {
        navigate("/bookingError", {state: {booking, message:error.response.data.message}})
      }
    }
  };

  const handleEditBooking = async () => {
    const data = await updateBookingById(booking)
    let newCredits = 0
    let priceDifference = previousPrice - booking.bookingPrice
    if (priceDifference >= 0) {
      newCredits = user.membership.credits + priceDifference
    } else {
      priceDifference = priceDifference * -1
      newCredits = user.membership.credits - priceDifference
    }
    setUser(prevState => (
      {...prevState, 
        membership: {
          ...prevState.membership, 
          credits: newCredits
        }
      }
    ))
    handleLocalStorageCredits(newCredits)
    navigate("/successBooking", {state: {booking: data} });
  };

  const handleBackButtonClick = () => {
    navigate(-1); 
  };

  const [modalOpen, setModalOpen] = useState(false)

  const terms =()=>{
    setModalOpen(true)
  }

  const closeModal =()=>{
    setModalOpen(false)
  }

  const [enable, setEnable] = useState(true)

  const accept =()=>{
    setEnable(false)
    setModalOpen(false)
  }

  return (
    <div className="login-container">
      <HeaderUserLogin />
      <main className="main-gradient">
        <img className='calendar-image' src={calendar} alt="Calendario" />
        <h2 className="title">¡Genial <span>Tenemos disponibilidad</span> <br></br> en la sala que elegiste! </h2>
      </main>
      {
        booking.room.img ? 
        <img className='room-images' src={booking.room.img} alt="Sala" /> : <></>
      }
      <div className="center mb-20">
        <h3>{booking.room.name}</h3>
        <h5>Día: {fixDateOffset(new Date(booking.date)).toLocaleDateString('es-ES', { year: "numeric", month: "long", day: "numeric" })}</h5>
        <h5>Horario: {booking.startHour}hs a {booking.endHour}hs</h5>
        {freeBookingUserTypes.indexOf(user.membership?.type) === -1 && <h5>Creditos: {booking.bookingPrice}</h5>}
        {
          enable === true ? 
          <div className="terms-container" onClick={terms}>
            <p className="terms">
              <img className="uncheck-icon" src={unCheck} alt="uncheck" />
                Para continuar debe ver y aceptar los términos y condiciones haciendo clic aquí
            </p>
          </div>
        :
          <div className="terms-container">
            <p className="terms terms-accept" onClick={terms}>
              <img className="checked-icon" src={checked} alt="Checked" />
                He leído y aceptado los términos y condiciones
            </p>
          </div>
        }
      </div>
      <Modal size="xl" fullscreen="xxl-down" isOpen={modalOpen}>
        <ModalHeader className="center">
          Términos y Condiciones
        </ModalHeader>
        <ModalBody style={{height: "70vh"}}>
          <embed 
            height="100%" 
            width="100%" 
            src="https://eurekaredirect.s3.amazonaws.com/Terminos+y+Condiciones+Eureka.pdf#toolbar=0&navpanes=0&scrollbar=0" 
          />
        </ModalBody>
        <ModalFooter className="center-row">
          <button onClick={closeModal} className="btn-secondary btn-small">Volver atrás</button>
          <button onClick={accept} className="btn-primary btn-small">Aceptar</button>
        </ModalFooter>
      </Modal>
      {
        booking.id &&
          <button className="btn-primary" onClick={handleEditBooking}>
            <img className="search-icon" src={okImage} alt="Search Icon" />
            Editar reserva
          </button> 
      }
      {
        !booking.id &&
        <button className="btn-primary" disabled={enable} onClick={handleConfirmButtonClick}>
          <img className="search-icon" src={okImage} alt="Search Icon" />
          Confirmar reserva
        </button> 
      }
      <button className="btn-secondary" onClick={async() => await handleBackButtonClick()}>Volver atrás</button>
      <FooterUserLogin />
    </div>
  );
};

export default ConfirmBooking;