import PhotoDescriptionRow from "./PhotoDescriptionRow";
import photo1 from "../../../img/foto-1.png";
import photo2 from "../../../img/foto-2.png";

const PhotosDescriptionContainer = () => {
  return (
    <div className="row">
      <PhotoDescriptionRow
        photo={photo1}
        title="Destinatarios del programa"
        description="Convocamos a jóvenes entre 16 y 35 años que pretendan desarrollar
            una Idea o Proyecto en la provincia de Formosa, y formar parte de la
            Red Eureka. En la misma se compartirán experiencias, actividades,
            charlas y formaciones en el marco de un programa de desarrollo de
            ideas y proyectos innovadores."
        span="ES PARA VOS"
      />
      <PhotoDescriptionRow
        photo={photo2}
        title="Como debe ser tu idea"
        description="Convocamos a jóvenes entre 16 y 35 años que pretendan desarrollar una Idea o Proyecto en la provincia de Formosa, y formar parte de la Red Eureka. En la misma se compartirán experiencias, actividades, charlas y formaciones en el marco de un programa de desarrollo de ideas y proyectos innovadores."
        span="¿QUÉ PUEDO HACER?"
      />
    </div>
  );
};

export default PhotosDescriptionContainer;
