export const bfsaUserTypes = [
  {
    value: 'gerente',
    label: 'Gerente'
  },
  {
    value: 'subgerente',
    label: 'Subgerente'
  },
  {
    value: 'mentor',
    label: 'Mentor'
  }
];

export const eurekaUserTypes = [
  {
    value: 'gold',
    label: 'Gold'
  },
  {
    value: 'silver',
    label: 'Silver'
  },
  {
    value: 'bronze',
    label: 'Bronze'
  },
  {
    value: 'mentor',
    label: 'Mentor'
  }
];

export const freeBookingUserTypes = [
  'gerente', 'subgerente', 'gold', 'mentor'
];