import React from "react";
import { Link } from "react-router-dom";
import logo from "../../../img/logo.png";
import "../Main/main.css";

const Header = () => {
    return (
        <div className="header">
            <img src={logo} alt="logo" />
            <Link to="/login" className="btn-primary btn-primary-header center">Socios</Link>
        </div>
    );
};

export default Header;