import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./userPanel.css";
import HeaderUserLogin from "../../Shared/Header";
import FooterUserLogin from "../../Shared/Footer";
import ellipse from "./../../../img/ellipse1.png";
import sheetImage from "../../../img/icon_sheet.png";
import { UserContext } from "../../../context/UserContext";
import adminUserBtn from "./../../../img/admin-user-btn.png";
import calendar from "./../../../img/mini_calendar.png";
import { validateUserIdentity } from "../../../helpers/utils";
import { getBookingsByDni } from "../../../services/bookings/getBookingsByDni";
import qr from "../../../img/qr-icon.png";
import { getUserById } from "../../../services/users/getUserById";
import { freeBookingUserTypes } from "../../../dictionaries/users";
const QR_FUNCTIONALITY = process.env.REACT_APP_QR_FUNCTIONALITY === "true";

const UserPanel = () => {
  const navigate = useNavigate();
  const { user, setUser } = useContext(UserContext);
  const [show, setShow] = useState("");
  const [srcImg, setSrcImg] = useState("");

  useEffect(() => {
    const refreshUser = async () => {
      try {
        const data = await getUserById(user.id);
        const refreshedUser = {
          id: data.id,
          role: data.role,
          username: data.username,
          name: data.name,
          lastname: data.lastname,
          dni: data.dni,
          membership: data.membership,
          project: data.project,
          origin: data.origin,
        }
        localStorage.setItem("user", JSON.stringify(refreshedUser));
        setUser({...refreshedUser})
      } catch (error) {
        console.log('Error al actualizar los datos');
      }
    };
    refreshUser();
    try {
      setSrcImg(require(`./../../../img/fotos/${user.membership?.id}.jpg`));
    } catch (error) {
      setSrcImg(require("./../../../img/people-default1.png"));
    }
  }, []);

  const handleBookingButtonClick = () => {
    navigate("/booking");
  };
  const handleBookingManagementButtonClick = () => {
    navigate("/rooms");
  }
  const handleShowBookingsAdminButtonClick = () => {
    navigate("/adminBookings");
  }
  const handlerAdminUsersButton = () => {
    navigate("/adminUsers");
  };
  const handleShowBookingButtonClick = async () => {
    try {
      const data = await getBookingsByDni(user.dni);
      navigate("/ShowBookings", { state: { bookings: data } });
    } catch (error) {
      setShow("show")
    }
  };
  const handlerCloseButton = () => {
    localStorage.removeItem("access_token");
    localStorage.removeItem("token_expires");
    localStorage.removeItem("user");
    window.location.reload();
  };

  const scannerButtonClick = () => {
    navigate("/scanner");
  }

  return (
    <div className="login-container">
      <HeaderUserLogin />
      <h2 className="title">
        ¡Bienvenido/a{" "}
        <span
          style={{ color: "#00BFFF" }}
        >{`${user.name} ${user.lastname}`}</span>
        !
      </h2>

      <div className="center col-md-2 mt-20">
        <img className="aside-image-selected" src={ellipse} alt="Ellipse" />
        <img className="user-img-selected" src={srcImg} alt="User Default" />
      </div>
      { (user.role === 'member' && validateUserIdentity())  && <>
        <h1>{`${user.name} ${user.lastname}`}</h1>
        {user.project && <label>{user.project}</label>}
        {user.membership?.year && <label>Promoción: {user.membership?.year}</label>}
        {user.membership?.id && <label>Número de socio: {user.membership?.id}</label>}
        {freeBookingUserTypes.indexOf(user.membership?.type) === -1 && <label>Créditos disponibles: {user.membership?.credits}</label>}
      </>}
      <a className="changeKey" href="/changepassword">Cambiar Clave</a>
      { user.role === "admin" ?
        <div className="admins-buttons">
          <button className="btn-secondary btn-admin" onClick={handleBookingManagementButtonClick}>
            <img className="sheet-icon" src={sheetImage} alt="Sheet Icon" />
            Gestión de salas y puestos de trabajo
          </button>
          <button className="btn-secondary btn-admin" onClick={handlerAdminUsersButton}>
            <img className="sheet-icon" src={adminUserBtn} alt="adminUserBtn" />
            Administración de socios
          </button>
          <button className="btn-secondary btn-admin" onClick={handleShowBookingsAdminButtonClick}>
            <img className="sheet-icon" src={calendar} alt="Sheet Icon" />
            Ver reservas
          </button>
          {QR_FUNCTIONALITY && <button className="btn-secondary btn-admin qr-btn" onClick={scannerButtonClick}>
            <img className="sheet-icon" src={qr} alt="qr Icon" />
            Escanear QR
          </button>}
        </div>
      :
        <>
          {validateUserIdentity() ? 
            <div className="users-buttons">
              <button className="btn-secondary reserve-button" onClick={handleBookingButtonClick}>
                <img className="sheet-icon" src={calendar} />
                Reservar salas y puestos de trabajo
              </button>
              <button className="btn-primary" onClick={async () => await handleShowBookingButtonClick()}>
                Ver mis reservas
              </button>
              <p className={`noReserve ${show}`}>No se encuentran reservas disponibles </p>
            </div>
        :
          <p>Su usuario aún no ha sido validado</p>}
        </>
      }
      <button className="btn-secondary" onClick={handlerCloseButton}>Cerrar sesión</button>

      <FooterUserLogin />
    </div>
  );
};

export default UserPanel;
