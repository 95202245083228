import { useState } from "react";
import { faArrowDown, faArrowUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./faq.css";

const FaqRow = ({ question, answer }) => {
  const [hiddenAnswer, sethiddenAnswer] = useState(true);
  const [downArrowIcon, setDownArrowIcon] = useState(false);

  const onHiddenAnswer = () => {
    sethiddenAnswer(!hiddenAnswer);
    setDownArrowIcon(!downArrowIcon);
  };

  return (
    <>
      <div className="row mb-4" onClick={onHiddenAnswer}>
        <div className="faqContainer">
          <FontAwesomeIcon
            color={`${hiddenAnswer ? "#00B7DD" : "#E31C79"}`}
            icon={downArrowIcon ? faArrowUp : faArrowDown}
          />
          <p
            style={{
              marginTop: "10px",
              marginLeft: "8px",
              color: `${hiddenAnswer ? "#00B7DD" : "#E31C79"}`,
            }}
          >
            {question}
          </p>
        </div>
        <div className="divider"></div>
        <div
          style={{
            transition: `max-height ${
              hiddenAnswer ? " 0.4s ease-out" : "0.9s ease-in"
            }`,
            maxHeight: hiddenAnswer ? "0" : "200px",
            overflow: "hidden",
          }}
        >
          <p>{answer}</p>
        </div>
      </div>
    </>
  );
};

export default FaqRow;
