import React, { useContext } from "react";
import HeaderUserLogin from "../Shared/Header";
import FooterUserLogin from "../Shared/Footer";
import cancelImage from '../../img/ic-cancel.png';
import { useNavigate, useLocation } from 'react-router-dom';
import { UserContext } from "../../context/UserContext";
import { fixDateOffset, handleLocalStorageCredits } from "../../helpers/utils";
import calendar from '../../img/calendar-cancel.png';
import { cancelBooking } from '../../services/bookings/cancelBooking';
import { freeBookingUserTypes } from "../../dictionaries/users";

const ConfirmCancelBooking = () => {
    const { user, setUser } = useContext(UserContext);
    const location = useLocation();
    const booking = location.state.booking;
    const navigate = useNavigate();

    const handlerCancelButton = async (booking) => {
        try {
            await cancelBooking(booking.id)
            const newCredits = user.membership.credits + booking.bookingPrice
            setUser(prevState => (
                {...prevState, 
                  membership: {
                    ...prevState.membership, 
                    credits: newCredits
                  }
                }
              ))
            handleLocalStorageCredits(newCredits)
            navigate('/CancelBooking', { state: { booking } });
        } catch (error) {
          navigate("/bookingError", {state: {booking, message:error.response.data.message}})
        }
        
    };

  const handleBackButtonClick = () => {
    navigate(-1); 
  };

  return (
        <div className="login-container">
            <HeaderUserLogin />
            <h1 className="title">
                Cancelar reserva para: “{booking.room.name}” 
            </h1>
            <img className='calendar-image' src={calendar} alt="Calendario" />
            <h2 className="title delete"> ¿Está seguro que desea cancelar su reserva? </h2>
            <h4>Día: {fixDateOffset(new Date(booking.date)).toLocaleDateString('es-ES', { year: "numeric", month: "long", day: "numeric" })}</h4>
            <h4>Horario: {booking.startHour}hs a {booking.endHour}hs</h4>
            {freeBookingUserTypes.indexOf(user.membership?.type) === -1 && <h4>Creditos: {booking.bookingPrice}</h4>}
            <button className="btn-delete btn-delete-booking" onClick={async() => await handlerCancelButton(booking)}>
                <img className="search-icon" src={cancelImage} alt="Cancel Icon" />
                Cancelar reserva
            </button> 
            <button className="btn-secondary" onClick={handleBackButtonClick}>Volver atrás</button>
            <FooterUserLogin />
        </div>
  )
}

export default ConfirmCancelBooking