import red from "../../../img/user-group-solid.svg"
import formacion from "../../../img/book-bookmark-solid.svg"
import coaching from "../../../img/child-reaching-solid.svg"
import apoyo from "../../../img/hand-holding-hand-solid.svg"
import eventos from "../../../img/champagne-glasses-solid.svg"

export const messages = [
    {title: "Red de emprendedores", text: "Acceso a nuestra Red de emprendedores y mentores.", icon: red},
    {title: "Formación", text: "Formación diseñada para emprendedores emergentes", icon: formacion},
    {title: "Coaching", text: "Acompañamiento y coaching en los desafíos de tu proyecto", icon: coaching},
    {title: "Apoyo", text: "Apoyo en las gestiones ante fuentes de financiamiento", icon: apoyo},
    {title: "Eventos especiales", text: "Participación e invitación a eventos especiales", icon: eventos},
]