import React from "react";
import { messages } from "./messages-services";
import "./services.css";

const Services = () => {
  return (
    <div className="services-container">
      <b className="bullet">{messages.services.bullet}</b>{" "}
      <b>{messages.services.pretitle}</b>
      <h2>
        {messages.services.title}{" "}
        <span className="light-blue">{messages.services.title2}</span>
      </h2>
      <h6>{messages.services.subtitle}</h6>
    </div>
  );
};

export default Services;