import { IdeaItem } from "./IdeaItem"

const IdeasContainer = () => {
  return (
    <div className="row ideasContainer">
      <IdeaItem />
    </div>
  )
}

export default IdeasContainer;