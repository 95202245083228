import React, { useEffect } from "react";
import { useNavigate, useLocation } from 'react-router-dom';
import HeaderUserLogin from "../Shared/Header";
import FooterUserLogin from "../Shared/Footer";
import successImage from "../../img/ic_confirm.png";
import timeImage from "../../img/ic_time.png";
import "../Booking/booking.css";

const SuccessRoom = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const room = location.state?.roomData;
  console.log(room);

  useEffect(() => {
    const redirectTimeout = setTimeout(() => {
      navigate("/rooms");
    }, 6000);

    return () => clearTimeout(redirectTimeout);
  }, []);

  return (
    <div className="login-container">
      <HeaderUserLogin />
      <img className="success-icon" src={successImage} alt="Success Icon" />
      <h1 className="title">
        <span style={{ color: "#00B7DD" }}>¡Guardado exitoso!</span>{" "}
      </h1>
      <label className="label">Nombre de sala: {room.name}</label>
      <label className="label">Tipo: {room.type === 'room' ? 'Sala' : 'Puesto de trabajo'}</label>
      <label className="label">Capacidad: {room.capacity}</label>
      <label className="label">Estado: Activo</label>
      <div className="time-container">
        <img className="time-icon" src={timeImage} alt="Time Icon" />
        <label>
          Te redireccionaremos <br></br> automáticamente al inicio
        </label>
      </div>
      <FooterUserLogin />
    </div>
  );
};

export default SuccessRoom;
