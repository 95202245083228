import React, { useState } from "react";
import "../Booking/booking.css";
import HeaderUserLogin from "../Shared/Header";
import FooterUserLogin from "../Shared/Footer";
import vectorImage from "../../img/ic_vector.png";
import { createRoom } from "../../services/rooms/createRoom";
import { useNavigate } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import Swal from "sweetalert2";

const NewRoom = () => {
  const navigate = useNavigate();
  const [roomName, setRoomName] = useState("");
  const [roomType, setRoomType] = useState("room");
  const [roomCapacity, setRoomCapacity] = useState(0);

  const handleSaveRoom = async () => {
    if (!roomName) {
      Swal.fire({title:"El nombre de la sala es obligatorio.", icon:"warning"});
      return;
    }
    if (!roomCapacity) {
      Swal.fire({title:"La capacidad de la sala es obligatoria.", icon:"warning"});
      return;
    }
    if (roomCapacity > 50) {Swal.fire({title:"La capacidad de la sala no puede ser tan elevada.", icon:"warning"}); return}
    try {
      const roomData = {
        name: roomName.trim(),
        type: roomType,
        capacity: roomCapacity,
        price: 1,
      };

      await createRoom(roomData);
      navigate("/successRoom", { state: { roomData: roomData } });
    } catch (error) {
      Swal.fire({title:"Error creando sala", icon:"error"});
    }
  };

  const handlerBackButton = () => {
    navigate("/rooms");
  };

  return (
    <div className="login-container">
      <HeaderUserLogin />
      <main className="main-gradient">
        <img className="vector-icon" src={vectorImage} alt="Search Icon" />
        <h1 className="title">
          Gestión de disponibilidad de salas y puestos de trabajo
        </h1>
      </main>
      <div className="create-room-container">
      <div className="select-container">
        <p className="subtitle">Nombre</p>
        <input
          className="rounded-select"
          type="text"
          value={roomName}
          onChange={(e) => setRoomName(e.target.value)}
        />
      </div>

      <div className="select-container">
        <p className="subtitle">Tipo</p>
        <select
          className="rounded-select"
          value={roomType}
          onChange={(e) => setRoomType(e.target.value)}
        >
          <option value="room">Sala</option>
          <option value="workstation">Puesto de trabajo</option>
        </select>
      </div>

      <div className="select-container">
        <p className="subtitle">Capacidad</p>
        <input
          className="rounded-select"
          type="number"
          pattern="[0-9]"
          min={0}
          value={roomCapacity}
          onChange={(e) => setRoomCapacity(Number(e.target.value))}
        />
      </div>
      <div className="group-buttons" style={{marginTop: '20px'}}>
        <button className="btn-primary" onClick={handleSaveRoom}>
          Guardar
        </button>
        <button className="btn-secondary" onClick={handlerBackButton}>
          Volver atrás
        </button>
      </div>
      </div>
      <FooterUserLogin />
    </div>
  );
};

export default NewRoom;
