import React from 'react'
import "./login.css";
import HeaderUserLogin from "../Shared/Header";
import FooterUserLogin from "../Shared/Footer";
import vectorImage from "../../img/okay.png";
import  { Navigate, useNavigate } from 'react-router-dom'

const SuccessRegister = () => {
    const navigate = useNavigate();

    const homeButton = () => {
        navigate("/");
    };

    const loginButton = () => {
    navigate("/login");
    };

  return (
    <>
        <HeaderUserLogin/>
        <div className='center margin-10'>
            <h2>¡Bienvenido a Eureka!</h2>
            <img  src={vectorImage} alt="Success" />
            <h3><span>¡Registro exitoso!</span></h3>
            <br/>
            <h5>Ya podes ingresar a tu panel de usuario para disfrutar de los beneficios de Eureka</h5>
            <div className='center margin-50'>
                <button className="btn-primary" onClick={loginButton}>
                    Logearse
                </button>
                <button className="btn-secondary" onClick={homeButton}>
                    Volver a Home
                </button>
            </div>
        </div>

        <FooterUserLogin/>
    </>
  )
}

export default SuccessRegister